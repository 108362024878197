import actionTypes from "../actionTypes";

const initialState = {
	// boot

	boot: {
		data: {},
		loading: false,
		success: false,
		error: false,
	},

	// redirect

	redirect: {
		to: null,
		external: false,
	},

	// auto redirect

	queuedRedirect: {
		to: null,
		external: false,
		after: null,
	},

	// modal

	modal: {
		name: null,
		data: null,
	},

	// global

	global: {
		isMobileResponsive: false,
		isMobile768: false,
		bodyNoScroll: false,
		headerHide: false,
	},

	// alert

	alert: {
		show: false,
		size: "mini",
		title: "",
		body: "",
		button: "",
	},

	// config

	config: {
		units: "m",
		altura: 2,
	},

	// loading general

	loading: false,

	// coupon

	coupon: "",
};

const appReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// boot

		case actionTypes.APP_BOOT_INIT:
			return {
				...state,
				boot: {
					...state.boot,
					...initialState.boot,
					loading: true,
				},
			};

		case actionTypes.APP_BOOT_SUCCESS:
			return {
				...state,
				boot: {
					...state.boot,
					...initialState.boot,
					data: payload,
					success: true,
				},
			};

		case actionTypes.APP_BOOT_ERROR:
			return {
				...state,
				boot: {
					...state.boot,
					...initialState.boot,
					error: true,
				},
				loading: initialState.loading,
			};

		// redirect

		case actionTypes.APP_REDIRECT:
			return {
				...state,
				redirect: {
					...state.redirect,
					...initialState.redirect,
					to: payload.to,
					external: payload.external,
				},
			};

		case actionTypes.APP_REDIRECT_RESET:
			return {
				...state,
				redirect: {
					...state.redirect,
					...initialState.redirect,
				},
			};

		// queued redirect

		case actionTypes.APP_QUEUED_REDIRECT:
			return {
				...state,
				queuedRedirect: {
					...state.queuedRedirect,
					...initialState.queuedRedirect,
					to: payload.to,
					external: payload.external,
					after: payload.after,
				},
			};

		case actionTypes.APP_QUEUED_REDIRECT_RESET:
			return {
				...state,
				queuedRedirect: {
					...state.queuedRedirect,
					...initialState.queuedRedirect,
				},
			};

		// queued redirect events

		case actionTypes.AUTH_SILENT_LOGIN_SUCCESS:
		case actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS:
		case actionTypes.AUTH_LOCAL_LOGIN_SUCCESS:
			if (state.queuedRedirect.after === "login") {
				return {
					...state,
					redirect: state.queuedRedirect,
					queuedRedirect: initialState.queuedRedirect,
					loading: initialState.loading,
				};
			} else {
				return {
					...state,
					loading: initialState.loading,
				};
			}

		// config

		case actionTypes.APP_CONFIG:
			return {
				...state,
				config: {
					...state.config,
					...payload,
				},
			};

		// modal

		case actionTypes.APP_MODAL:
			if (!payload || !payload.name) {
				return {
					...state,
					modal: {
						...state.modal,
						...initialState.modal,
					},
				};
			} else {
				return {
					...state,
					modal: {
						...state.modal,
						...payload,
					},
				};
			}

		case actionTypes.APP_MODAL_CLOSE_ALL:
			return {
				...state,
				modal: initialState.modal,
			};

		// global

		case actionTypes.APP_SET_IS_MOBILE_RESPONSIVE:
			return {
				...state,
				global: {
					...state.global,
					isMobileResponsive: payload,
				},
			};

		case actionTypes.APP_SET_IS_MOBILE_768:
			return {
				...state,
				global: {
					...state.global,
					isMobile768: payload,
				},
			};

		case actionTypes.APP_SET_NO_SCROLL:
			return {
				...state,
				global: {
					...state.global,
					bodyNoScroll: payload,
				},
			};

		case actionTypes.APP_SET_HEADER_HIDE:
			return {
				...state,
				global: {
					...state.global,
					headerHide: payload,
				},
			};

		// alert

		case actionTypes.ALERT_SHOW:
			return {
				...state,
				alert: {
					...state.alert,
					...payload,
					show: true,
				},
			};

		case actionTypes.ALERT_HIDE:
			return {
				...state,
				alert: initialState.alert,
			};

		// loading true

		case actionTypes.CENTRO_LOAD_INIT:
		// case actionTypes.CENTROS_LOAD_INIT:
		case actionTypes.LUGAR_LOAD_INIT:
		case actionTypes.LUGARES_LOAD_INIT:
		case actionTypes.POST_LOAD_INIT:
		case actionTypes.POSTS_LOAD_INIT:
		case actionTypes.CONTACTO_INIT:
		case actionTypes.AUTH_LOCAL_LOGIN_INIT:
		case actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_INIT:
		case actionTypes.AUTH_SOCIAL_LOGIN_INIT:
		case actionTypes.AUTH_PERFIL_SAVE_INIT:
		case actionTypes.AUTH_LOCAL_REGISTER_INIT:
		case actionTypes.AUTH_PASSWORD_RECOVERY_INIT:
		case actionTypes.AUTH_PASSWORD_CHANGE_INIT:
		// case actionTypes.FAVORITOS_LOAD_INIT:
		case actionTypes.FAVORITO_ADD_INIT:
		case actionTypes.FAVORITO_DELETE_INIT:
		// case actionTypes.ALERTAS_LOAD_INIT:
		case actionTypes.ALERTA_ADD_INIT:
		case actionTypes.ALERTA_DELETE_INIT:
			return {
				...state,
				loading: true,
			};

		// loading false

		case actionTypes.CENTRO_LOAD_SUCCESS:
		case actionTypes.CENTRO_LOAD_ERROR:
		case actionTypes.CENTRO_LOAD_END:
		// case actionTypes.CENTROS_LOAD_END:
		case actionTypes.LUGAR_LOAD_END:
		case actionTypes.LUGARES_LOAD_END:
		case actionTypes.POST_LOAD_END:
		case actionTypes.POSTS_LOAD_END:
		case actionTypes.CONTACTO_SUCCESS:
		case actionTypes.CONTACTO_ERROR:
		// case actionTypes.AUTH_LOCAL_LOGIN_SUCCESS: // lo saco de aca porque este evento tambien hace otras cosas, ver en este mismo file
		case actionTypes.AUTH_LOCAL_LOGIN_ERROR:
		case actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_SUCCESS:
		case actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_ERROR:
		// case actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS: // lo saco de aca porque este evento tambien hace otras cosas, ver en este mismo file
		case actionTypes.AUTH_SOCIAL_LOGIN_ERROR:
		case actionTypes.AUTH_PERFIL_SAVE_SUCCESS:
		case actionTypes.AUTH_PERFIL_SAVE_ERROR:
		case actionTypes.AUTH_LOCAL_REGISTER_SUCCESS:
		case actionTypes.AUTH_LOCAL_REGISTER_ERROR:
		case actionTypes.AUTH_PASSWORD_RECOVERY_SUCCESS:
		case actionTypes.AUTH_PASSWORD_RECOVERY_ERROR:
		case actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS:
		case actionTypes.AUTH_PASSWORD_CHANGE_ERROR:
		case actionTypes.FAVORITOS_LOAD_SUCCESS:
		case actionTypes.FAVORITOS_LOAD_ERROR:
		case actionTypes.FAVORITO_ADD_SUCCESS:
		case actionTypes.FAVORITO_ADD_ERROR:
		case actionTypes.FAVORITO_DELETE_SUCCESS:
		case actionTypes.FAVORITO_DELETE_ERROR:
		case actionTypes.ALERTAS_LOAD_SUCCESS:
		case actionTypes.ALERTAS_LOAD_ERROR:
		case actionTypes.ALERTA_ADD_SUCCESS:
		case actionTypes.ALERTA_ADD_ERROR:
		case actionTypes.ALERTA_DELETE_SUCCESS:
		case actionTypes.ALERTA_DELETE_ERROR:
			return {
				...state,
				loading: initialState.loading,
			};

		// app coupon

		case actionTypes.APP_SET_COUPON:
			return {
				...state,
				coupon: payload,
			};

		default:
			return state;
	}
};

export default appReducer;
