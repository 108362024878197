import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processAlertasLoadInit(action) {
	const { payload } = action;
	try {
		const token = yield localStorage.getItem("token");
		const { lang } = payload;
		if (token) {
			const response = yield call(API.getAlertas, lang, token);
			yield put({
				type: actionTypes.ALERTAS_LOAD_SUCCESS,
				payload: response,
			});
		} else {
			yield put({
				type: actionTypes.ALERTAS_LOAD_ERROR,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.ALERTAS_LOAD_ERROR,
		});
	}
}

export function* processAlertaAddInit(action) {
	const { payload } = action;
	try {
		const { lang, id } = payload;
		const token = yield localStorage.getItem("token");
		const response = yield call(API.addAlerta, lang, token, id);
		yield put({
			type: actionTypes.ALERTA_ADD_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `Alertas`,
				body: "La alerta se ha creado exitosamente.",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.ALERTA_ADD_ERROR,
		});
	}
}

export function* processAlertaDeleteInit(action) {
	const { payload } = action;
	try {
		const { lang, id } = payload;
		const token = yield localStorage.getItem("token");
		const response = yield call(API.deleteAlerta, lang, token, id);
		yield put({
			type: actionTypes.ALERTA_DELETE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `Alertas`,
				body: "La alerta ha sido eliminada.",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.ALERTA_DELETE_ERROR,
		});
	}
}
