import API from "../../classes/api";
import { put, call } from "redux-saga/effects";
import actionTypes from "../actionTypes";

export function* processLocalLoginInit(action) {
	console.log("authSagas/processLocalLoginInit", action);
	const { lang, email, password } = action.payload;
	try {
		const response = yield call(API.localLogin, lang, email, password);
		const { token, nombre } = response.data;
		yield localStorage.setItem("token", token);
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_MODAL,
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `¡Hola ${nombre}!`,
				body: "Nos alegra que estés por aquí.",
			},
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processRecoveryLoginInit(action) {
	console.log("authSagas/processRecoveryLoginInit", action);
	const { lang, email, key } = action.payload;
	try {
		const response = yield call(API.recoveryLogin, lang, email, key);
		const { token, nombre } = response.data;
		yield localStorage.setItem("token", token);
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_MODAL,
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `¡Hola ${nombre}!`,
				body: "Nos alegra que estés por aquí.",
			},
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processLocalLoginEmailCheckInit(action) {
	console.log("authSagas/processLocalLoginEmailCheckInit", action);
	const { lang, email } = action.payload;
	try {
		const response = yield call(API.localLoginEmailCheck, lang, email);
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processSilentLoginSuccess(action) {
	console.log("authSagas/processSilentLoginSuccess", action);
	const { token, lang } = action.payload;
	try {
		yield localStorage.setItem("token", token);
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
	} catch (error) {}
}

export function* processSilentLoginError(action) {
	console.log("authSagas/processSilentLoginError", action);
	try {
		yield localStorage.removeItem("token");
	} catch (error) {}
}

export function* processLogout(action) {
	console.log("authSagas/processLogout", action);
	try {
		yield localStorage.removeItem("token");
	} catch (error) {}
}

export function* processPerfilSaveInit(action) {
	console.log("authSagas/processPerfilSaveInit", action);
	const { lang, data } = action.payload;
	try {
		const response = yield call(API.savePerfil, lang, data);
		yield put({
			type: actionTypes.AUTH_PERFIL_SAVE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
			payload: {
				usuario: response.data,
				lang,
				token: response.data.token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PERFIL_SAVE_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processLocalRegisterInit(action) {
	console.log("authSagas/processLocalRegisterInit", action);
	const { lang, data } = action.payload;
	try {
		const response = yield call(API.localRegister, lang, data);
		yield localStorage.setItem("token", response.data.token);
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
			// payload: response.data,
			payload: {
				usuario: response.data,
				lang,
				token: response.data.token,
			},
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/subscriptions" },
		});
		yield put({
			type: actionTypes.APP_MODAL,
		});
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_RESET,
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token: response.data.token,
			},
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token: response.data.token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_LOCAL_REGISTER_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processSocialLoginInit(action) {
	console.log("authSagas/processSocialLoginInit", action);
	try {
		const { lang, data } = action.payload;
		const response = yield call(API.socialLogin, lang, data);
		const { token, nombre } = response.data;
		yield localStorage.setItem("token", token);
		yield put({
			type: actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_MODAL,
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `¡Hola ${nombre}!`,
				body: "Nos alegra que estés por aquí.",
			},
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.ALERTAS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_SOCIAL_LOGIN_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processPasswordRecoveryInit(action) {
	console.log("authSagas/processPasswordRecoveryInit", action);
	const { lang, data } = action.payload;
	try {
		const response = yield call(API.passwordRecovery, lang, data);
		yield put({
			type: actionTypes.AUTH_PASSWORD_RECOVERY_SUCCESS,
			payload: response,
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PASSWORD_RECOVERY_ERROR,
			payload: error.data.response.payload,
		});
	}
}

export function* processPasswordChangeInit(action) {
	console.log("authSagas/processPasswordChangeInit", action);
	const { lang, data } = action.payload;
	try {
		const response = yield call(API.passwordChange, lang, data);
		yield put({
			type: actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.APP_REDIRECT,
			payload: { to: "/account" },
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `¡Hecho!`,
				body: "Tu contraseña ha sido modificada.",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.AUTH_PASSWORD_CHANGE_ERROR,
			payload: error.data.response.payload,
		});
	}
}
