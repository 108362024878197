const initialState = {
	// lugares
	data: [],
	loading: false,
	success: false,
	error: false,
};

const lugaresReducer = (state = initialState, action) => {
	const { type } = action;

	switch (type) {
		default:
			return state;
	}
};

export default lugaresReducer;
