import actionTypes from "./actionTypes";
import { takeLatest, all, takeEvery } from "redux-saga/effects";
import * as appSagas from "./app/sagas";
import * as centroSagas from "./centro/sagas";
import * as centrosSagas from "./centros/sagas";
import * as lugarSagas from "./lugar/sagas";
import * as contactoSagas from "./contacto/sagas";
import * as authSagas from "./auth/sagas";
import * as notificationsSagas from "./notifications/sagas";
import * as favoritosSagas from "./favoritos/sagas";
import * as alertasSagas from "./alertas/sagas";

function* rootSaga() {
	yield all([
		takeLatest(actionTypes.APP_BOOT_INIT, appSagas.processAppBootInit),
		takeLatest(
			actionTypes.CENTROS_LOAD_INIT,
			centrosSagas.processCentrosLoadInit,
		),
		takeLatest(
			actionTypes.CENTROS_FILTERS_SET_AND_LOAD_INIT,
			centrosSagas.processCentrosFiltersSetAndLoadInit,
		),
		takeLatest(
			actionTypes.CENTRO_LOAD_INIT,
			centroSagas.processCentroLoadInit,
		),
		takeLatest(
			actionTypes.LUGAR_CENTROS_LOAD_INIT,
			lugarSagas.processLugarCentrosLoadInit,
		),
		takeLatest(actionTypes.CONTACTO_INIT, contactoSagas.processContactoInit),
		takeLatest(
			actionTypes.AUTH_LOCAL_LOGIN_INIT,
			authSagas.processLocalLoginInit,
		),
		takeLatest(
			actionTypes.AUTH_RECOVERY_LOGIN_INIT,
			authSagas.processRecoveryLoginInit,
		),
		takeLatest(
			actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_INIT,
			authSagas.processLocalLoginEmailCheckInit,
		),
		takeLatest(
			actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
			authSagas.processSilentLoginSuccess,
		),
		takeLatest(
			actionTypes.AUTH_SILENT_LOGIN_ERROR,
			authSagas.processSilentLoginError,
		),
		takeLatest(actionTypes.AUTH_LOGOUT, authSagas.processLogout),
		takeEvery(
			actionTypes.AUTH_PERFIL_SAVE_INIT,
			authSagas.processPerfilSaveInit,
		),
		takeLatest(
			actionTypes.AUTH_LOCAL_REGISTER_INIT,
			authSagas.processLocalRegisterInit,
		),
		takeLatest(
			actionTypes.AUTH_SOCIAL_LOGIN_INIT,
			authSagas.processSocialLoginInit,
		),
		takeLatest(
			actionTypes.AUTH_PASSWORD_RECOVERY_INIT,
			authSagas.processPasswordRecoveryInit,
		),
		takeLatest(
			actionTypes.AUTH_PASSWORD_CHANGE_INIT,
			authSagas.processPasswordChangeInit,
		),
		takeEvery(
			actionTypes.NOTIFICATIONS_ADD,
			notificationsSagas.processNotificationAdd,
		),
		takeLatest(
			actionTypes.FAVORITOS_LOAD_INIT,
			favoritosSagas.processFavoritosLoadInit,
		),
		takeEvery(
			actionTypes.FAVORITO_ADD_INIT,
			favoritosSagas.processFavoritoAddInit,
		),
		takeEvery(
			actionTypes.FAVORITO_DELETE_INIT,
			favoritosSagas.processFavoritoDeleteInit,
		),
		takeLatest(
			actionTypes.ALERTAS_LOAD_INIT,
			alertasSagas.processAlertasLoadInit,
		),
		takeEvery(
			actionTypes.ALERTA_ADD_INIT,
			alertasSagas.processAlertaAddInit,
		),
		takeEvery(
			actionTypes.ALERTA_DELETE_INIT,
			alertasSagas.processAlertaDeleteInit,
		),
	]);
}

export default rootSaga;
