import axios from "axios";

const apiHost = "https://api.freshsnow.pro/";
const apiEndpoint = `${apiHost}<lang>/api-v4/`;

const apiBoot = `${apiEndpoint}bootweb/`;
const apiCentros = `${apiEndpoint}centros/`;
const apiFavoritos = `${apiEndpoint}favoritos/`;
const apiAlertas = `${apiEndpoint}alertas/`;
const apiLocalLogin = `${apiEndpoint}locallogin/`;
const apiRecoveryLogin = `${apiEndpoint}recoverylogin/`;
const apiLocalLoginEmailCheck = `${apiEndpoint}localloginemailcheck/`;
const apiGoogleLogin = `${apiEndpoint}googlelogin/`;
const apiFacebookLogin = `${apiEndpoint}facebooklogin/`;
const apiAppleLogin = `${apiEndpoint}applelogin/`;
const apiPerfil = `${apiEndpoint}perfil/`;
const apiPasswordRecovery = `${apiEndpoint}passwordrecovery/`;
const apiPasswordChange = `${apiEndpoint}passwordchange/`;
const apiNewsletter = `${apiEndpoint}newsletter/`;
const apiContacto = `${apiEndpoint}contacto/`;

class API {
	static getBoot = (lang) => {
		console.log("API/getBoot");
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(apiBoot.replace("<lang>", lang), {
					timeout: 20000,
				});
				console.log("API/getBoot Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getBoot Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getBoot Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getBoot Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getCentros = (lang, data) => {
		console.log("API/getCentros", lang, data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiCentros.replace("<lang>", lang),
					{
						timeout: 20000,
						params: data,
					},
				);
				console.log("API/getCentros Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getBoot Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getBoot Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getBoot Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getCentro = (lang, data) => {
		console.log("API/getCentro", lang, data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiCentros.replace("<lang>", lang),
					{
						timeout: 20000,
						params: data,
					},
				);
				console.log("API/getCentro Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log("API/getBoot Response ERROR", error.response.data);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getBoot Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getBoot Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localLogin = (lang, email, password) => {
		console.log("API/localLogin", email, password);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiLocalLogin.replace("<lang>", lang),
					{
						email,
						password,
					},
				);
				console.log("API/localLogin Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/localLogin Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/localLogin Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/localLogin Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static recoveryLogin = (lang, email, key) => {
		console.log("API/recoveryLogin", email, key);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiRecoveryLogin.replace("<lang>", lang),
					{
						email,
						key,
					},
				);
				console.log("API/recoveryLogin Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/recoveryLogin Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/recoveryLogin Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/recoveryLogin Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localLoginEmailCheck = (lang, email) => {
		console.log("API/localLoginEmailCheck", email);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiLocalLoginEmailCheck.replace("<lang>", lang),
					{
						email,
					},
				);
				console.log("API/localLoginEmailCheck Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/localLoginEmailCheck Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/localLoginEmailCheck Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/localLoginEmailCheck Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static socialLogin = (lang, data) => {
		console.log("API/socialLogin", data);
		const { provider } = data;
		if (["google", "facebook", "apple"].indexOf(provider) > -1) {
			var apiSocialLogin = null;
			switch (provider) {
				case "google":
					apiSocialLogin = apiGoogleLogin;
					break;
				case "facebook":
					apiSocialLogin = apiFacebookLogin;
					break;
				case "apple":
					apiSocialLogin = apiAppleLogin;
					break;
				default:
					return null;
			}
			return new Promise(async (resolve, reject) => {
				try {
					const response = await axios.post(
						apiSocialLogin.replace("<lang>", lang),
						data,
					);
					console.log("API/socialLogin Response OK", response);
					resolve(response.data.data.response.payload);
				} catch (error) {
					if (error.response) {
						console.log(
							"API/socialLogin Response ERROR Response",
							error.response.data,
						);
						reject(error.response.data);
					} else if (error.request) {
						console.log(
							"API/socialLogin Response ERROR Request",
							error.request,
						);
						reject(null);
					} else {
						console.log("API/socialLogin Response ERROR", error);
						reject(null);
					}
				}
			});
		} else {
			return new Promise(async (resolve, reject) => {
				reject(null);
			});
		}
	};

	static getPerfil = (lang, token) => {
		console.log("API/getPerfil", token);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					`${apiPerfil.replace("<lang>", lang)}?token=${token}`,
				);
				console.log("API/getPerfil Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getPerfil Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/getPerfil Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/getPerfil Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static savePerfil = (lang, data) => {
		console.log("API/guardarPerfil", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.patch(
					apiPerfil.replace("<lang>", lang),
					data,
				);
				console.log("API/guardarPerfil Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/guardarPerfil Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/guardarPerfil Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/guardarPerfil Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static localRegister = (lang, data) => {
		console.log("API/localRegister", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiPerfil.replace("<lang>", lang),
					data,
				);
				console.log("API/localRegister Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/localRegister Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/localRegister Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/localRegister Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static passwordRecovery = (lang, data) => {
		console.log("API/passwordRecovery", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiPasswordRecovery.replace("<lang>", lang),
					data,
				);
				console.log("API/passwordRecovery Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/passwordRecovery Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/passwordRecovery Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/passwordRecovery Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static passwordChange = (lang, data) => {
		console.log("API/passwordChange", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiPasswordChange.replace("<lang>", lang),
					data,
				);
				console.log("API/passwordChange Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/passwordChange Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/passwordChange Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/passwordChange Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static addNewsletter = (lang, data) => {
		console.log("API/addNewsletter", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiNewsletter.replace("<lang>", lang),
					data,
				);
				console.log("API/addNewsletter Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/addNewsletter Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/addNewsletter Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/addNewsletter Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static contacto = (lang, data) => {
		console.log("API/contacto", data);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiContacto.replace("<lang>", lang),
					data,
				);
				console.log("API/contacto Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/contacto Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/contacto Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/contacto Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getFavoritos = (lang, token) => {
		console.log("API/getFavoritos", lang, token);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiFavoritos.replace("<lang>", lang) +
						"?include_clima=1&clima_mode=full&token=" +
						token,
					{
						timeout: 20000,
					},
				);
				console.log("API/getFavoritos Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getFavoritos Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getFavoritos Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getFavoritos Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static deleteFavorito = (lang, token, id) => {
		console.log("API/deleteFavorito", lang, token, id);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.delete(
					apiFavoritos.replace("<lang>", lang) +
						`?token=${token}&id=${id}`,
				);
				console.log("API/deleteFavorito Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/deleteFavorito Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/deleteFavorito Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/deleteFavorito Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static addFavorito = (lang, token, id) => {
		console.log("API/addFavorito", lang, token, id);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiFavoritos.replace("<lang>", lang),
					{ token, id },
				);
				console.log("API/addFavorito Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/addFavorito Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/addFavorito Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/addFavorito Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static getAlertas = (lang, token) => {
		console.log("API/getAlertas", lang, token);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(
					apiAlertas.replace("<lang>", lang) +
						"?include_clima=1&clima_mode=full&token=" +
						token,
					{
						timeout: 20000,
					},
				);
				console.log("API/getAlertas Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/getAlertas Response ERROR",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log("API/getAlertas Response ERROR", error.request);
					reject(null);
				} else {
					console.log("API/getAlertas Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static deleteAlerta = (lang, token, id) => {
		console.log("API/deleteAlerta", lang, token, id);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.delete(
					apiAlertas.replace("<lang>", lang) + `?token=${token}&id=${id}`,
				);
				console.log("API/deleteAlerta Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/deleteAlerta Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/deleteAlerta Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/deleteAlerta Response ERROR", error);
					reject(null);
				}
			}
		});
	};

	static addAlerta = (lang, token, id) => {
		console.log("API/addAlerta", lang, token, id);
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.post(
					apiAlertas.replace("<lang>", lang),
					{ token, id },
				);
				console.log("API/addAlerta Response OK", response);
				resolve(response.data.data.response.payload);
			} catch (error) {
				if (error.response) {
					console.log(
						"API/addAlerta Response ERROR Response",
						error.response.data,
					);
					reject(error.response.data);
				} else if (error.request) {
					console.log(
						"API/addAlerta Response ERROR Request",
						error.request,
					);
					reject(null);
				} else {
					console.log("API/addAlerta Response ERROR", error);
					reject(null);
				}
			}
		});
	};
}

export default API;
