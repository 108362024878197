const actionTypes = {
	// APP BOOT

	APP_BOOT_INIT: "APP_BOOT_INIT",
	APP_BOOT_SUCCESS: "APP_BOOT_SUCCESS",
	APP_BOOT_ERROR: "APP_BOOT_ERROR",
	APP_BOOT_END: "APP_BOOT_END",

	// APP REDIRECT

	APP_REDIRECT: "APP_REDIRECT",
	APP_REDIRECT_RESET: "APP_REDIRECT_RESET",

	// APP QUEUED REDIRECT

	APP_QUEUED_REDIRECT: "APP_QUEUED_REDIRECT",
	APP_QUEUED_REDIRECT_RESET: "APP_QUEUED_REDIRECT_RESET",

	// APP CONFIG

	APP_CONFIG: "APP_CONFIG",

	// APP MODAL

	APP_MODAL: "APP_MODAL",
	APP_MODAL_CLOSE_ALL: "APP_MODAL_CLOSE_ALL",
	APP_MODAL_CLOSE: "APP_MODAL_CLOSE",

	// APP GLOBAL

	APP_SET_IS_MOBILE_RESPONSIVE: "APP_SET_IS_MOBILE_RESPONSIVE",
	APP_SET_IS_MOBILE_768: "APP_SET_IS_MOBILE_768",
	APP_SET_NO_SCROLL: "APP_SET_NO_SCROLL",
	APP_SET_HEADER_HIDE: "APP_SET_HEADER_HIDE",

	// APP COUPON

	APP_SET_COUPON: "APP_SET_COUPON",

	// CENTRO

	CENTRO_LOAD_INIT: "CENTRO_LOAD_INIT",
	CENTRO_LOAD_SUCCESS: "CENTRO_LOAD_SUCCESS",
	CENTRO_LOAD_ERROR: "CENTRO_LOAD_ERROR",
	CENTRO_LOAD_END: "CENTRO_LOAD_END",
	CENTRO_CERCANOS_LOAD_SUCCESS: "CENTRO_CERCANOS_LOAD_SUCCESS",
	CENTRO_CERCANOS_LOAD_ERROR: "CENTRO_CERCANOS_LOAD_ERROR",

	// CENTROS

	CENTROS_LOAD_INIT: "CENTROS_LOAD_INIT",
	CENTROS_LOAD_SUCCESS: "CENTROS_LOAD_SUCCESS",
	CENTROS_LOAD_ERROR: "CENTROS_LOAD_ERROR",
	CENTROS_LOAD_END: "CENTROS_LOAD_END",
	CENTROS_FILTERS_SET: "CENTROS_FILTERS_SET",
	CENTROS_FILTERS_SET_AND_LOAD_INIT: "CENTROS_FILTERS_SET_AND_LOAD_INIT",
	CENTROS_DATA_RESET: "CENTROS_DATA_RESET",

	// LUGAR

	LUGAR_LOAD_INIT: "LUGAR_LOAD_INIT",
	LUGAR_LOAD_SUCCESS: "LUGAR_LOAD_SUCCESS",
	LUGAR_LOAD_ERROR: "LUGAR_LOAD_ERROR",
	LUGAR_LOAD_END: "LUGAR_LOAD_END",
	LUGAR_CENTROS_LOAD_INIT: "LUGAR_CENTROS_LOAD_INIT",
	LUGAR_CENTROS_LOAD_SUCCESS: "LUGAR_CENTROS_LOAD_SUCCESS",
	LUGAR_CENTROS_LOAD_ERROR: "LUGAR_CENTROS_LOAD_ERROR",

	// LUGARES

	LUGARES_LOAD_INIT: "LUGARES_LOAD_INIT",
	LUGARES_LOAD_SUCCESS: "LUGARES_LOAD_SUCCESS",
	LUGARES_LOAD_ERROR: "LUGARES_LOAD_ERROR",
	LUGARES_LOAD_END: "LUGARES_LOAD_END",

	// POST

	POST_LOAD_INIT: "POST_LOAD_INIT",
	POST_LOAD_SUCCESS: "POST_LOAD_SUCCESS",
	POST_LOAD_ERROR: "POST_LOAD_ERROR",
	POST_LOAD_END: "POST_LOAD_END",

	// POSTS

	POSTS_LOAD_INIT: "POSTS_LOAD_INIT",
	POSTS_LOAD_SUCCESS: "POSTS_LOAD_SUCCESS",
	POSTS_LOAD_ERROR: "POSTS_LOAD_ERROR",
	POSTS_LOAD_END: "POSTS_LOAD_END",

	// CONTACTO

	CONTACTO_SET: "CONTACTO_SET",
	CONTACTO_INIT: "CONTACTO_INIT",
	CONTACTO_SUCCESS: "CONTACTO_SUCCESS",
	CONTACTO_ERROR: "CONTACTO_ERROR",

	// ALERT

	ALERT_SHOW: "ALERT_SHOW",
	ALERT_HIDE: "ALERT_HIDE",

	// AUTH - LOCAL LOGIN

	AUTH_LOCAL_LOGIN_EMAIL_CHECK_INIT: "AUTH_LOCAL_LOGIN_EMAIL_CHECK_INIT",
	AUTH_LOCAL_LOGIN_EMAIL_CHECK_SUCCESS: "AUTH_LOCAL_LOGIN_EMAIL_CHECK_SUCCESS",
	AUTH_LOCAL_LOGIN_EMAIL_CHECK_ERROR: "AUTH_LOCAL_LOGIN_EMAIL_CHECK_ERROR",
	AUTH_LOCAL_LOGIN_INIT: "AUTH_LOCAL_LOGIN_INIT",
	AUTH_LOCAL_LOGIN_SUCCESS: "AUTH_LOCAL_LOGIN_SUCCESS",
	AUTH_LOCAL_LOGIN_ERROR: "AUTH_LOCAL_LOGIN_ERROR",

	// AUTH - RECOVERY LOGIN

	AUTH_RECOVERY_LOGIN_INIT: "AUTH_RECOVERY_LOGIN_INIT",

	// AUTH - SOCIAL LOGIN

	AUTH_SOCIAL_LOGIN_INIT: "AUTH_SOCIAL_LOGIN_INIT",
	AUTH_SOCIAL_LOGIN_SUCCESS: "AUTH_SOCIAL_LOGIN_SUCCESS",
	AUTH_SOCIAL_LOGIN_ERROR: "AUTH_SOCIAL_LOGIN_ERROR",

	// AUTH - SILENT LOGIN

	AUTH_SILENT_LOGIN_SUCCESS: "AUTH_SILENT_LOGIN_SUCCESS",
	AUTH_SILENT_LOGIN_ERROR: "AUTH_SILENT_LOGIN_ERROR",

	// AUTH - LOGOUT

	AUTH_LOGOUT: "AUTH_LOGOUT",

	// AUTH - PERFIL SAVE

	AUTH_PERFIL_SAVE_INIT: "AUTH_PERFIL_SAVE_INIT",
	AUTH_PERFIL_SAVE_SUCCESS: "AUTH_PERFIL_SAVE_SUCCESS",
	AUTH_PERFIL_SAVE_ERROR: "AUTH_PERFIL_SAVE_ERROR",

	// AUTH - LOCAL REGISTER

	AUTH_LOCAL_REGISTER_SET_DATA: "AUTH_LOCAL_REGISTER_SET_DATA",
	AUTH_LOCAL_REGISTER_PAGE_BACK: "AUTH_LOCAL_REGISTER_PAGE_BACK",
	AUTH_LOCAL_REGISTER_INIT: "AUTH_LOCAL_REGISTER_INIT",
	AUTH_LOCAL_REGISTER_SUCCESS: "AUTH_LOCAL_REGISTER_SUCCESS",
	AUTH_LOCAL_REGISTER_ERROR: "AUTH_LOCAL_REGISTER_ERROR",
	AUTH_LOCAL_REGISTER_RESET: "AUTH_LOCAL_REGISTER_RESET",

	// AUTH - PASSWORD RECOVERY

	AUTH_PASSWORD_RECOVERY_INIT: "AUTH_PASSWORD_RECOVERY_INIT",
	AUTH_PASSWORD_RECOVERY_SUCCESS: "AUTH_PASSWORD_RECOVERY_SUCCESS",
	AUTH_PASSWORD_RECOVERY_ERROR: "AUTH_PASSWORD_RECOVERY_ERROR",
	AUTH_PASSWORD_RECOVERY_RESET: "AUTH_PASSWORD_RECOVERY_RESET",

	// AUTH - PASSWORD CHANGE

	AUTH_PASSWORD_CHANGE_INIT: "AUTH_PASSWORD_CHANGE_INIT",
	AUTH_PASSWORD_CHANGE_SUCCESS: "AUTH_PASSWORD_CHANGE_SUCCESS",
	AUTH_PASSWORD_CHANGE_ERROR: "AUTH_PASSWORD_CHANGE_ERROR",

	// FAVORITOS

	FAVORITOS_LOAD_INIT: "FAVORITOS_LOAD_INIT",
	FAVORITOS_LOAD_SUCCESS: "FAVORITOS_LOAD_SUCCESS",
	FAVORITOS_LOAD_ERROR: "FAVORITOS_LOAD_ERROR",

	FAVORITO_ADD_INIT: "FAVORITO_ADD_INIT",
	FAVORITO_ADD_SUCCESS: "FAVORITO_ADD_SUCCESS",
	FAVORITO_ADD_ERROR: "FAVORITO_ADD_ERROR",

	FAVORITO_DELETE_INIT: "FAVORITO_DELETE_INIT",
	FAVORITO_DELETE_SUCCESS: "FAVORITO_DELETE_SUCCESS",
	FAVORITO_DELETE_ERROR: "FAVORITO_DELETE_ERROR",

	// ALERTAS

	ALERTAS_LOAD_INIT: "ALERTAS_LOAD_INIT",
	ALERTAS_LOAD_SUCCESS: "ALERTAS_LOAD_SUCCESS",
	ALERTAS_LOAD_ERROR: "ALERTAS_LOAD_ERROR",

	ALERTA_ADD_INIT: "ALERTA_ADD_INIT",
	ALERTA_ADD_SUCCESS: "ALERTA_ADD_SUCCESS",
	ALERTA_ADD_ERROR: "ALERTA_ADD_ERROR",

	ALERTA_DELETE_INIT: "ALERTA_DELETE_INIT",
	ALERTA_DELETE_SUCCESS: "ALERTA_DELETE_SUCCESS",
	ALERTA_DELETE_ERROR: "ALERTA_DELETE_ERROR",

	// NOTIFICATIONS

	NOTIFICATIONS_ADD: "NOTIFICATIONS_ADD",
	NOTIFICATIONS_ADD_ID: "NOTIFICATIONS_ADD_ID",
	NOTIFICATIONS_REMOVE: "NOTIFICATIONS_REMOVE",
};

export default actionTypes;
