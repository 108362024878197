import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processAppBootInit(action) {
	try {
		const boot = yield call(API.getBoot, action.payload); // en el payload solo viene el lang
		yield put({
			type: actionTypes.APP_BOOT_SUCCESS,
			payload: boot,
		});
		// token
		const token = yield localStorage.getItem("token");
		if (token) {
			try {
				const response = yield call(API.getPerfil, action.payload, token);
				yield put({
					type: actionTypes.AUTH_SILENT_LOGIN_SUCCESS,
					// payload: response.data,
					payload: {
						token,
						lang: action.payload,
						usuario: response.data,
					},
				});
			} catch (error) {
				yield put({
					type: actionTypes.AUTH_SILENT_LOGIN_ERROR,
				});
			}
		} else {
			yield put({
				type: actionTypes.AUTH_SILENT_LOGIN_ERROR,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.APP_BOOT_ERROR,
		});
	}
}
