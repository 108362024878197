import actionTypes from "../actionTypes";
import { put, call, delay } from "redux-saga/effects";
import API from "../../classes/api";

export function* processCentroLoadInit(action) {
	const { payload } = action;
	try {
		const centro = yield call(API.getCentro, payload.lang, payload.data);
		yield put({
			type: actionTypes.CENTRO_LOAD_SUCCESS,
			payload: centro,
		});
		if (centro.lat && centro.long) {
			try {
				yield delay(1000);
				const cercanos = yield call(API.getCentros, payload.lang, {
					lat: centro.lat,
					long: centro.long,
					items_per_page: 10,
					page: 1,
					sort: "distancia",
					include_clima: 1,
					distancia: 300,
				});
				yield put({
					type: actionTypes.CENTRO_CERCANOS_LOAD_SUCCESS,
					payload: cercanos,
				});
			} catch (error) {
				yield put({
					type: actionTypes.CENTRO_CERCANOS_LOAD_ERROR,
				});
			}
		} else {
			yield put({
				type: actionTypes.CENTRO_CERCANOS_LOAD_ERROR,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.CENTRO_LOAD_ERROR,
		});
	}
}
