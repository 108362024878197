import actionTypes from "../actionTypes";

const initialState = {
	// lugar
	data: {},
	centros: [],
	loading: false,
	success: false,
	error: false,
};

const lugarReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.LUGAR_CENTROS_LOAD_SUCCESS:
			return {
				...state,
				centros: payload,
			};

		case actionTypes.LUGAR_CENTROS_LOAD_ERROR:
			return {
				...state,
				centros: initialState.cercanos,
			};

		default:
			return state;
	}
};

export default lugarReducer;
