import { combineReducers } from "redux";
import app from "./app/reducer";
import auth from "./auth/reducer";
import centro from "./centro/reducer";
import centros from "./centros/reducer";
import lugar from "./lugar/reducer";
import lugares from "./lugares/reducer";
import post from "./post/reducer";
import posts from "./posts/reducer";
import contacto from "./contacto/reducer";
import notifications from "./notifications/reducer";
import favoritos from "./favoritos/reducer";
import alertas from "./alertas/reducer";

const reducers = combineReducers({
	app,
	auth,
	centro,
	centros,
	lugar,
	lugares,
	post,
	posts,
	contacto,
	notifications,
	favoritos,
	alertas,
});

export default reducers;
