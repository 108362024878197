const initialState = {
	// post
	data: {},
	loading: false,
	success: false,
	error: false,
};

const postReducer = (state = initialState, action) => {
	const { type } = action;

	switch (type) {
		default:
			return state;
	}
};

export default postReducer;
