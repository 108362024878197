import actionTypes from "../actionTypes";

const initialState = {
	// centros
	data: [],
	loading: false,
	success: false,
	error: false,
	filters: {
		location: {
			title: "",
			lat: null,
			long: null,
		},
		distancia: 300,
		sort: "distancia",
	},
	page: 1,
};

const centrosReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.CENTROS_LOAD_INIT:
			return {
				...state,
				loading: true,
				success: false,
				error: false,
			};

		case actionTypes.CENTROS_FILTERS_SET_AND_LOAD_INIT:
			return {
				...state,
				loading: true,
				success: false,
				error: false,
			};

		case actionTypes.CENTROS_LOAD_SUCCESS:
			return {
				...state,
				data:
					state.filters.page === 1 ? payload : [...state.data, ...payload],
				loading: false,
				success: true,
				error: false,
				page: state.page + 1,
			};

		case actionTypes.CENTROS_LOAD_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				error: true,
			};

		case actionTypes.CENTROS_FILTERS_SET:
			return {
				...state,
				filters: {
					...state.filters,
					...payload,
				},
			};

		case actionTypes.CENTROS_DATA_RESET:
			return {
				...state,
				data: initialState.data,
				page: initialState.page,
			};

		default:
			return state;
	}
};

export default centrosReducer;
