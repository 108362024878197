import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processContactoInit(action) {
	const { payload } = action;
	try {
		const response = yield call(API.contacto, payload.lang, payload.data);
		yield put({
			type: actionTypes.CONTACTO_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.ALERT_SHOW,
			payload: {
				title: payload.data.messages.success.title,
				body: payload.data.messages.success.body,
				button: payload.data.messages.success.button,
			},
		});
		yield put({
			type: actionTypes.APP_MODAL,
		});
	} catch (error) {
		yield put({
			type: actionTypes.CONTACTO_ERROR,
		});
		yield put({
			type: actionTypes.ALERT_SHOW,
			payload: {
				title: payload.data.messages.error.title,
				body: payload.data.messages.error.body,
				button: payload.data.messages.error.button,
			},
		});
	}
}
