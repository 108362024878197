import actionTypes from "../actionTypes";

const initialState = {
	localLoginEmailExists: null,
	usuario: null,
	loggedIn: false,
	resolved: false,
	registerData: {},
	loginErrors: [],
	registerErrors: [],
	perfilSaveErrors: [],
	passwordRecoveryMailSent: false,
	passwordChangeErrors: [],
};

const authReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// auth local login

		case actionTypes.AUTH_LOCAL_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: payload.data,
				loginErrors: initialState.loginErrors,
				localLoginEmailExists: initialState.localLoginEmailExists,
			};

		case actionTypes.AUTH_LOCAL_LOGIN_ERROR:
			return {
				...state,
				loggedIn: false,
				resolved: true,
				loginErrors: payload.errors,
			};

		// auth local login email check

		case actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_SUCCESS:
			return {
				...state,
				localLoginEmailExists: true,
			};

		case actionTypes.AUTH_LOCAL_LOGIN_EMAIL_CHECK_ERROR:
			return {
				...state,
				localLoginEmailExists: false,
			};

		case actionTypes.APP_MODAL_CLOSE:
		case actionTypes.APP_MODAL_CLOSE_ALL:
			return {
				...state,
				localLoginEmailExists: initialState.localLoginEmailExists,
				registerData: initialState.registerData,
				registerErrors: initialState.registerErrors,
			};

		// auth social login

		case actionTypes.AUTH_SOCIAL_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: payload.data,
			};

		// auth silent login

		case actionTypes.AUTH_SILENT_LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				resolved: true,
				usuario: payload.usuario,
			};

		case actionTypes.AUTH_SILENT_LOGIN_ERROR:
			return {
				...state,
				loggedIn: false,
				resolved: true,
				usuario: {
					...state.usuario,
					...initialState.usuario,
				},
			};

		// auth register

		case actionTypes.AUTH_LOCAL_REGISTER_SET_DATA:
			return {
				...state,
				registerData: {
					...state.registerData,
					[payload.field]: payload.value,
				},
			};

		case actionTypes.AUTH_LOCAL_REGISTER_RESET:
			return {
				...state,
				registerData: initialState.registerData,
				registerErrors: initialState.registerErrors,
			};

		case actionTypes.AUTH_LOCAL_REGISTER_ERROR:
			return {
				...state,
				registerErrors: payload.errors,
			};

		// auth perfil

		case actionTypes.AUTH_PERFIL_SAVE_INIT:
			return {
				...state,
				perfilSaveErrors: initialState.perfilSaveErrors,
			};

		case actionTypes.AUTH_PERFIL_SAVE_SUCCESS:
			return {
				...state,
				usuario: payload.data,
				perfilSaveErrors: initialState.perfilSaveErrors,
			};

		case actionTypes.AUTH_PERFIL_SAVE_ERROR:
			return {
				...state,
				perfilSaveErrors: payload,
			};

		// auth pass recovery

		case actionTypes.AUTH_PASSWORD_RECOVERY_SUCCESS:
			return {
				...state,
				passwordRecoveryMailSent: true,
			};

		case actionTypes.AUTH_PASSWORD_RECOVERY_RESET:
			return {
				...state,
				passwordRecoveryMailSent: false,
			};

		// auth pass change

		case actionTypes.AUTH_PASSWORD_CHANGE_ERROR:
			return {
				...state,
				passwordChangeErrors: payload.errors,
			};

		case actionTypes.AUTH_PASSWORD_CHANGE_INIT:
		case actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS:
			return {
				...state,
				passwordChangeErrors: initialState.passwordChangeErrors,
			};

		// auth logout

		case actionTypes.AUTH_LOGOUT:
			return {
				...state,
				usuario: initialState.usuario,
				loggedIn: false,
				resolved: true,
			};

		default:
			return state;
	}
};

export default authReducer;
