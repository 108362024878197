import actionTypes from "../actionTypes";

const initialState = {
	// centro
	data: {},
	cercanos: [],
	loading: false,
	success: false,
	error: false,
};

const centroReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.CENTRO_LOAD_INIT:
			return {
				...state,
				loading: true,
				success: false,
				error: false,
			};

		case actionTypes.CENTRO_LOAD_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
				success: true,
				error: false,
			};

		case actionTypes.CENTRO_LOAD_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				error: true,
			};

		case actionTypes.CENTRO_CERCANOS_LOAD_SUCCESS:
			return {
				...state,
				cercanos: payload,
			};

		case actionTypes.CENTRO_CERCANOS_LOAD_ERROR:
			return {
				...state,
				cercanos: initialState.cercanos,
			};

		default:
			return state;
	}
};

export default centroReducer;
