import API from "../../classes/api";
import actionTypes from "../actionTypes";
import { call, put } from "redux-saga/effects";

export function* processLugarCentrosLoadInit(action) {
	const { payload } = action;
	try {
		const centros = yield call(API.getCentros, payload.lang, payload.data);
		yield put({
			type: actionTypes.LUGAR_CENTROS_LOAD_SUCCESS,
			payload: centros,
		});
	} catch (error) {
		yield put({
			type: actionTypes.LUGAR_CENTROS_LOAD_ERROR,
		});
	}
}
