import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processCentrosLoadInit(action) {
	const { payload } = action;
	if (payload.data.page && payload.data.page === 1) {
		yield put({
			type: actionTypes.CENTROS_DATA_RESET,
		});
	}
	try {
		const centros = yield call(API.getCentros, payload.lang, payload.data);
		yield put({
			type: actionTypes.CENTROS_LOAD_SUCCESS,
			payload: centros,
		});
	} catch (error) {
		yield put({
			type: actionTypes.CENTROS_LOAD_ERROR,
		});
	}
}

export function* processCentrosFiltersSetAndLoadInit(action) {
	const { lang, filters, data } = action.payload;
	yield put({
		type: actionTypes.CENTROS_DATA_RESET,
	});
	yield put({
		type: actionTypes.CENTROS_FILTERS_SET,
		payload: filters,
	});
	try {
		const centros = yield call(API.getCentros, lang, data);
		yield put({
			type: actionTypes.CENTROS_LOAD_SUCCESS,
			payload: centros,
		});
	} catch (error) {
		yield put({
			type: actionTypes.CENTROS_LOAD_ERROR,
		});
	}
}
