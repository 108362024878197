import actionTypes from "../actionTypes";

const initialState = {
	items: [],
	loading: false,
	loadingSuccess: false,
	loadingError: false,
	currentId: null,
	adding: false,
	addingSuccess: false,
	addingError: false,
	deleting: false,
	deletingSuccess: false,
	deletingError: false,
};

const favoritosReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.FAVORITOS_LOAD_INIT:
			return {
				...state,
				loading: true,
				loadingSuccess: false,
				loadingError: false,
			};

		case actionTypes.FAVORITOS_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				loadingSuccess: true,
				loadingError: false,
				items: payload,
				currentId: null,
			};

		case actionTypes.FAVORITOS_LOAD_ERROR:
			return {
				...state,
				loading: false,
				loadingSuccess: false,
				loadingError: true,
			};

		case actionTypes.AUTH_LOGOUT:
			return {
				...state,
				items: initialState.items,
			};

		case actionTypes.FAVORITO_ADD_INIT:
			return {
				...state,
				adding: true,
				addingSuccess: false,
				addingError: false,
				currentId: payload.id,
			};

		case actionTypes.FAVORITO_ADD_SUCCESS:
			return {
				...state,
				adding: false,
				addingSuccess: true,
				addingError: false,
			};

		case actionTypes.FAVORITO_ADD_ERROR:
			return {
				...state,
				adding: false,
				addingSuccess: false,
				addingError: true,
			};

		case actionTypes.FAVORITO_DELETE_INIT:
			return {
				...state,
				deleting: true,
				deletingSuccess: false,
				deletingError: false,
				currentId: payload.id,
			};

		case actionTypes.FAVORITO_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				deletingSuccess: true,
				deletingError: false,
			};

		case actionTypes.FAVORITO_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				deletingSuccess: false,
				deletingError: true,
			};

		default:
			return state;
	}
};

export default favoritosReducer;
