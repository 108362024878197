import actionTypes from "../actionTypes";

const initialState = {
	nombre: "",
	email: "",
	mensaje: "",
	loading: false,
};

const contactoReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.CONTACTO_SET:
			return {
				...state,
				...payload,
			};

		case actionTypes.CONTACTO_INIT:
			return {
				...state,
				loading: true,
			};

		case actionTypes.CONTACTO_SUCCESS:
			return {
				...state,
				...initialState,
				loading: false,
			};

		case actionTypes.CONTACTO_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};

export default contactoReducer;
