import actionTypes from "../actionTypes";
import l from "lodash";

const initialState = {
	notifications: [],
};

const notificationsReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		// boot

		case actionTypes.NOTIFICATIONS_ADD_ID:
			return {
				...state,
				notifications: [...state.notifications, payload],
			};

		case actionTypes.NOTIFICATIONS_REMOVE:
			console.log("removing", payload);
			return {
				...state,
				notifications: l.remove(
					[...state.notifications],
					(notification) => {
						return notification.id !== payload;
					},
				),
			};

		default:
			return state;
	}
};

export default notificationsReducer;
