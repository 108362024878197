import actionTypes from "../actionTypes";
import { put, call } from "redux-saga/effects";
import API from "../../classes/api";

export function* processFavoritosLoadInit(action) {
	const { payload } = action;
	try {
		const token = yield localStorage.getItem("token");
		const { lang } = payload;
		if (token) {
			const response = yield call(API.getFavoritos, lang, token);
			yield put({
				type: actionTypes.FAVORITOS_LOAD_SUCCESS,
				payload: response,
			});
		} else {
			yield put({
				type: actionTypes.FAVORITOS_LOAD_ERROR,
			});
		}
	} catch (error) {
		yield put({
			type: actionTypes.FAVORITOS_LOAD_ERROR,
		});
	}
}

export function* processFavoritoAddInit(action) {
	const { payload } = action;
	try {
		const { lang, id } = payload;
		const token = yield localStorage.getItem("token");
		const response = yield call(API.addFavorito, lang, token, id);
		yield put({
			type: actionTypes.FAVORITO_ADD_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `Favoritos`,
				body: "Has agregado el resort a tu lista de favoritos.",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.FAVORITO_ADD_ERROR,
		});
	}
}

export function* processFavoritoDeleteInit(action) {
	const { payload } = action;
	try {
		const { lang, id } = payload;
		const token = yield localStorage.getItem("token");
		const response = yield call(API.deleteFavorito, lang, token, id);
		yield put({
			type: actionTypes.FAVORITO_DELETE_SUCCESS,
			payload: response,
		});
		yield put({
			type: actionTypes.FAVORITOS_LOAD_INIT,
			payload: {
				lang,
				token,
			},
		});
		yield put({
			type: actionTypes.NOTIFICATIONS_ADD,
			payload: {
				title: `Favoritos`,
				body: "Has quitado el resort de tu lista de favoritos.",
			},
		});
	} catch (error) {
		yield put({
			type: actionTypes.FAVORITO_DELETE_ERROR,
		});
	}
}
