import actionTypes from "../actionTypes";
import { put, delay } from "redux-saga/effects";
import l from "lodash";

export function* processNotificationAdd(notification) {
	const id = l.uniqueId("notification");
	yield put({
		type: actionTypes.NOTIFICATIONS_ADD_ID,
		payload: {
			id,
			...notification.payload,
		},
	});
	yield delay(5000);
	yield put({
		type: actionTypes.NOTIFICATIONS_REMOVE,
		payload: id,
	});
}
